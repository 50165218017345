import { View, Text, StyleSheet, ImageBackground, Button, TextInput } from 'react-native';
import { useState } from 'react';
import Footer from '../components/Footer';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { isMobile } from '../helpers/deviceUtils';
import { Image, Pressable, ScrollView } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import AutoInsuranceTabs from './autoInsurance';
import { useRef } from 'react';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const [zipCode, setZipCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const scrollViewRef = useRef();
    const navigate = useNavigate()

    return (
        <View style={styles.root}>
            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <Text style={styles.title}>{messages.title}</Text>
                    <Image source={require('../assets/header-icon.png')} style={styles.headerIcon} />

                </View>
            </View>

            <View style={styles.contentView}>
                <ImageBackground source={require('../assets/background.png')}
                    style={styles.image} />
                <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContainer}
                    ref={scrollViewRef}>
                    <View style={styles.mainContent} >
                        <View style={styles.zipCodeView}>
                            <View style={styles.descriptionView} >
                                <Text style={styles.description}>{messages.description}</Text>
                                <Text style={styles.descriptionIcon}>{messages.zero}</Text>
                            </View>
                            {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
                            <View style={styles.inputView}>
                                <TextInput style={styles.textInput}
                                    placeholder={messages.zipCode}
                                    placeholderTextColor={'rgba(25,28,31,0.5)'}
                                    value={zipCode}
                                    inputMode='numeric'
                                    maxLength={5}
                                    onChangeText={(newValue) => {
                                        setZipCode(newValue);
                                    }} />


                                <Pressable style={[styles.buttonView]}
                                    onPress={() => {
                                        const isValid = validator.isPostalCode(zipCode, "US");
                                        if (isValid) {
                                            setErrorMessage("");
                                            scrollViewRef.current?.scrollToEnd();
                                        } else {
                                            setErrorMessage(ERROR_MESSAGES.ZIP_CODE)
                                        }
                                    }}>
                                    <Text style={styles.buttonContent}>{messages.seeQuotes}</Text>
                                </Pressable>
                            </View>
                        </View>
                        <AutoInsuranceTabs zipCode={zipCode} />
                    </View>
                </ScrollView>
            </View>

            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
        },
        header: {
            height: window.innerHeight * 0.1,
            backgroundColor: '#FFF84B',
            justifyContent: "center"
        },
        headerIcon: {
            width: 100,
            height: "90%",
            resizeMode: 'contain'
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "800",
            fontFamily: 'DidactGothic-Regular',
        },
        contentView: {
            height: window.innerHeight * 0.9,
            alignItems: "center",
            width: "100%",
        },
        scrollView: {
            flex: 1,
            width: "100%",
            height: '80%',
            position: "absolute"
        },
        zipCodeView: {
            width: "100%",
            height: '50%'
        },
        scrollViewContainer: {
            alignItems: "center"
        },
        image: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        },
        descriptionView: {
            flexDirection: "row",
        },
        description: {
            color: "#FFF84B",
            fontWeight: "700",
            fontFamily: 'DidactGothic-Regular',
            width: 500,
        },
        descriptionIcon: {
            color: "#FFF84B",
            fontWeight: "700",
            fontSize: 120,
            lineHeight: 120,
        },
        error: {
            color: "red",
            fontWeight: "400",
            fontFamily: 'DidactGothic-Regular',
            lineHeight: 24
        },
        inputView: {
            flexDirection: "row",
            marginTop: 40
        },
        textInput: {
            backgroundColor: "#FFF84B",
            borderRadius: 10,
            fontWeight: 400,
            fontFamily: 'DidactGothic-Regular',
            padding: 15,
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "#0097B2",
            marginLeft: 20,
            position: "relative",
            borderRadius: 10,
            paddingVertical: 5,
            paddingHorizontal: 15,
            fontWeight: "500",
            alignItems: "center",
            justifyContent: "center",
        },
        buttonContent: {
            color: "#FFF84B",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "700",
            fontFamily: 'DidactGothic-Regular',
            padding: 14,
        },
        mainContent: {
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5%",
            maxWidth: 670,
            height: '140vh',
            position: "absolute",
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            width: '100%',
        },
        headerContent: {
            marginLeft: 215,
            height: '90%',
            flexDirection: "row",
            alignItems: "center"
        },
        title: {
            ...baseStyles.title,
            fontSize: 22,
        },
        contentView: {
            ...baseStyles.contentView
        },
        description: {
            ...baseStyles.description,
            fontSize: 64,
            lineHeight: 66,
        },
        error: {
            ...baseStyles.error,
            fontSize: 24,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 12,
            width: 220
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        header: {
            ...baseStyles.header,
            width: window.innerWidth,
        },
        headerContent: {
            marginLeft: "10%",
            flexDirection: "row",
            alignItems: "center"
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "flex-start"
        },
        mainContent: {
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            marginTop: 65,
            paddingRight: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 30,
            lineHeight: 38
        },
        error: {
            ...baseStyles.error,
            fontSize: 20,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    title: "Home Grid Experts",
    description: "Let’s get your energy bills to...",
    zero: "$0",
    zipCode: "ENTER YOUR ZIP CODE",
    seeQuotes: "GET TO $0",
}

export default AutoHomePage;
