import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Privacy Policy `}</Text>
            <Text style={styles.subHeader}>{`Effective Date: June 1, 2022`}</Text>
            <Text style={styles.description}>{`HomeGridExperts.com (""HomeGridExperts.com"", ""we"", ""our"", or ""us"") is committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and protect information collected from users of our website(s) and app(s) accessed through Internet-connected computers, televisions, or mobile devices (e.g., mobile phones, tablets), or otherwise (collectively, the ""Services"").`}</Text>
            <Text style={styles.title}>{`Information We Collect`}</Text>
            <Text style={styles.description}>{`1.1. Information You Provide:`}</Text>
            <Text style={styles.subDescription}>{`When you register for an account, we collect certain information such as your name, email address, and any other information you provide voluntarily.`}</Text>
            <Text style={styles.subDescription}>{`If you contact us for customer support or other inquiries, we may collect your name, email address, and any other information you provide during the communication.`}</Text>
            <Text style={styles.description}>{`1.2. Automatically Collected Information:`}</Text>
            <Text style={styles.subDescription}>{`When you use our Services, we may automatically collect certain information about your device, including your IP address, browser type, operating system, and device identifiers.`}</Text>
            <Text style={styles.subDescription}>{`We may also collect information about your usage of our Services, such as the pages you visit, the features you use, and the actions you take.`}</Text>
            <Text style={styles.description}>{`1.3. Cookies and Similar Technologies:`}</Text>
            <Text style={styles.subDescription}>{`We may use cookies, web beacons, and similar tracking technologies to collect information about your interactions with our Services.`}</Text>
            <Text style={styles.subDescription}>{`These technologies help us personalize your experience, analyze trends, and track user movements.`}</Text>
            <Text style={styles.title}>{`Use of Information`}</Text>
            <Text style={styles.description}>{`2.1. We may use the information we collect for the following purposes:`}</Text>
            <Text style={styles.subDescription}>{`To provide and improve our Services, including customization and personalization features.`}</Text>
            <Text style={styles.subDescription}>{`To communicate with you, respond to your inquiries, and provide customer support.`}</Text>
            <Text style={styles.subDescription}>{`To analyze usage trends, monitor the effectiveness of our marketing efforts, and gather demographic information.`}</Text>
            <Text style={styles.subDescription}>{`To detect, prevent, and address technical issues, security incidents, and fraudulent activities.`}</Text>
            <Text style={styles.subDescription}>{`To comply with legal obligations and enforce our Terms of Use and other policies.`}</Text>

            <Text style={styles.title}>{`Disclosure of Information`}</Text>
            <Text style={styles.description}>{`3.1. We may share your information with third parties in the following circumstances:`}</Text>
            <Text style={styles.subDescription}>{`With service providers who assist us in providing and improving our Services, subject to confidentiality obligations.`}</Text>
            <Text style={styles.subDescription}>{`With affiliated companies and subsidiaries for internal purposes, such as data processing and analysis.`}</Text>
            <Text style={styles.subDescription}>{`In response to legal requests, court orders, or government regulations.`}</Text>
            <Text style={styles.subDescription}>{`In connection with a merger, acquisition, or sale of assets, where your information may be transferred to the acquiring entity.`}</Text>

            <Text style={styles.title}>{`Data Security`}</Text>
            <Text style={styles.description}>{`4.1. We take reasonable measures to protect the security of your information and prevent unauthorized access, disclosure, alteration, or destruction.`}</Text>
            <Text style={styles.description}>{`4.2. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.`}</Text>

            <Text style={styles.title}>{`Your Choices`}</Text>
            <Text style={styles.description}>{`5.1. You may choose not to provide certain information, but this may limit your ability to access certain features of our Services.`}</Text>
            <Text style={styles.description}>{`5.2. You can opt-out of certain data collection and marketing communications by adjusting your browser settings or contacting us directly.`}</Text>

            <Text style={styles.title}>{`Children's Privacy`}</Text>
            <Text style={styles.description}>{`6.1. Our Services are not directed to children under the age of 18, and we do not knowingly collect personal information from individuals under 18 years of age.`}</Text>

            <Text style={styles.title}>{`Changes to this Privacy Policy`}</Text>
            <Text style={styles.description}>{`7.1. We may update this Privacy Policy from time to time by posting the revised version on our website.`}</Text>
            <Text style={styles.description}>{`7.2. Your continued use of our Services after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.`}</Text>
            <Text style={styles.title}>{`Contact Us`}</Text>
            <Text style={styles.description}>{`8.1. If you have any questions, concerns, or complaints about this Privacy Policy or our data practices, please contact us at hello@homegridexperts.com.`}</Text>
            <Text style={styles.description}>{`By using our Services, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.`}</Text>
        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        subDescription: {
            color: "#0B3C49",
            fontSize: 14,
            fontWeight: "400",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 5,
        },

    }
)

export default PrivacyPage;
