import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Terms of Use`}</Text>
            <Text style={styles.subHeader}>{`Effective Date: June 1, 2022`}</Text>

            <Text style={styles.description}>{`Welcome to HomeGridExperts.com!`}</Text>
            <Text style={styles.description}>{`As you step into our digital domain, get ready to embark on a journey filled with ingenious solutions and unparalleled experiences. Before you dive into the wonders of HomeGridExperts.com, take a moment to acquaint yourself with our Terms of Use. By accessing or using any of our Services, you are not just scrolling through mundane legal jargon; you're venturing into a realm where innovation meets responsibility.`}</Text>
            <Text style={styles.title}>{`1. Eligibility and Registration:`}</Text>
            <Text style={styles.description}>{`Become part of our vibrant community by registering for an account. We promise it's not your typical signup process. We're all about authenticity here, so make sure your details are as genuine as your enthusiasm for our Services. Oh, and keep those passwords under lock and key; we're all about safeguarding your digital sanctum.`}</Text>
            <Text style={styles.title}>{`2. License:`}</Text>
            <Text style={styles.description}>{`Consider this your exclusive backstage pass to HomeGridExperts.com. With our limited, personal license, you gain access to a world of possibilities. Just remember, this pass is for personal use only; no commercial shenanigans allowed. Think of it as your VIP ticket to innovation-town.`}</Text>
            <Text style={styles.title}>{`3. Proprietary Rights:`}</Text>
            <Text style={styles.description}>{`We're like the guardians of digital treasure chests, protecting our intellectual property with the tenacity of a dragon guarding its hoard. Our Services are a symphony of creativity and innovation, and we're the conductors ensuring every note is heard loud and clear.`}</Text>
            <Text style={styles.title}>{`4. Use of the Services:`}</Text>
            <Text style={styles.description}>{`Picture yourself navigating the digital seas with us as your trusty co-captain. But heed this warning: steer clear of stormy waters. Misusing our Services is like sailing into a tempest; it won't end well. So, play by the rules, and we'll ensure smooth sailing ahead.`}</Text>
            <Text style={styles.title}>{`5. Termination or Suspension of the Services & Modification of the Terms of Use:`}</Text>
            <Text style={styles.description}>{`Ahoy there! Brace yourself for some occasional choppy waters. We reserve the right to adjust sails and alter course if needed. But don't worry, we'll give you fair warning before any storm clouds gather on the horizon.`}</Text>
            <Text style={styles.title}>{`6. Loyalty Program:`}</Text>
            <Text style={styles.description}>{`Who doesn't love a good ol' loyalty program? Stick with us, and we'll make sure your dedication doesn't go unnoticed. No purchases necessary; just your unwavering support.`}</Text>
            <Text style={styles.title}>{`7. User Submissions:`}</Text>
            <Text style={styles.description}>{`Ever dreamt of showcasing your talent to the world? Well, now's your chance. Share your creations with us, but remember, with great power comes great responsibility. Keep it clean, keep it classy, and who knows, you might just become the next digital sensation.`}</Text>
            <Text style={styles.title}>{`8. Feedback:`}</Text>
            <Text style={styles.description}>{`Consider us your digital confidants. We're all ears for your suggestions, feedback, and ideas. Pour your heart out, and together, we'll shape the future of HomeGridExperts.com.`}</Text>
            <Text style={styles.title}>{`9. Indemnification:`}</Text>
            <Text style={styles.description}>{`In this digital adventure, we've got your back. But remember, with great power comes great responsibility. Play nice, and we'll ensure you're well-protected from any digital skirmishes.`}</Text>
            <Text style={styles.title}>{`10. Disclaimer of Warranty:`}</Text>
            <Text style={styles.description}>{`Consider this our digital fine print. While we strive for perfection, we're only human (well, mostly). So, take our Services with a pinch of digital salt; we're here to enhance your experience, not guarantee perfection.`}</Text>
            <Text style={styles.title}>{`11. Limitation of Liability:`}</Text>
            <Text style={styles.description}>{`Navigating the digital seas can be treacherous at times. But fear not, we've got a sturdy ship and a crew that's second to none. In the unlikely event of rough waters, rest assured we'll steer you to safety.`}</Text>
            <Text style={styles.title}>{`12. Release:`}</Text>
            <Text style={styles.description}>{`Think of this as our digital peace treaty. We promise not to hold you accountable for any digital mishaps, and we expect the same courtesy in return. Let's keep the digital realm a safe and harmonious place for all.`}</Text>
            <Text style={styles.title}>{`13. Governing Law and Forum:`}</Text>
            <Text style={styles.description}>{`When it comes to digital disputes, we like to keep things simple. Think of us as the digital judge and jury, here to ensure fairness and justice prevail.`}</Text>
            <Text style={styles.title}>{`14. Binding Arbitration:`}</Text>
            <Text style={styles.description}>{`In the unlikely event of a digital showdown, we prefer to settle things amicably. Think of arbitration as our digital olive branch, extended in the spirit of cooperation and understanding.`}</Text>
            <Text style={styles.title}>{`15. Force Majeure:`}</Text>
            <Text style={styles.description}>{`Sometimes, even the mightiest digital warriors face unforeseen challenges. Consider this our digital force field, protecting us from the whims of fate.`}</Text>
            <Text style={styles.title}>{`16. Relationship of the Parties:`}</Text>
            <Text style={styles.description}>{`Here at HomeGridExperts.com, we believe in digital camaraderie. We may be the captains of this digital ship, but we're all in this together.`}</Text>
            <Text style={styles.title}>{`17. Notice; Consent to Electronic Notice:`}</Text>
            <Text style={styles.description}>{`In this digital age, paper trails are so last century. Embrace the digital revolution, and let's keep the lines of communication open and efficient.`}</Text>
            <Text style={styles.title}>{`18. Third-Party Links:`}</Text>
            <Text style={styles.description}>{`Think of these links as digital portals to undiscovered realms. Venture forth with caution, and who knows what digital wonders you might uncover.`}</Text>
            <Text style={styles.title}>{`19. App Distributors:`}</Text>
            <Text style={styles.description}>{`Ah, the digital bazaars of the app world. Explore to your heart's content, but remember, not all digital treasures are created equal. Choose wisely, and may your digital adventures be fruitful.`}</Text>
            <Text style={styles.title}>{`20. Miscellaneous:`}</Text>
            <Text style={styles.description}>{`Consider this our digital fine-tuning. We're constantly evolving, adapting, and improving to ensure your digital journey with us is nothing short of extraordinary.`}</Text>
            <Text style={styles.title}>{`21. No Unsolicited Submissions:`}</Text>
            <Text style={styles.description}>{`We're like digital guardians, protecting our digital realm from unwanted intruders. So, unless you've got a golden ticket from Willy Wonka himself, keep those unsolicited submissions at bay.`}</Text>
            <Text style={styles.title}>{`22. Intellectual Property Infringement:`}</Text>
            <Text style={styles.description}>{`We take intellectual property rights seriously in this digital realm. If you spot any digital pirates, sound the alarm, and we'll spring into action.`}</Text>
            <Text style={styles.title}>{`23. Questions:`}</Text>
            <Text style={styles.description}>{`Got a digital query that needs answering? Drop us a line, and we'll do our best to guide you through the digital maze. email hello@homegridexperts.com`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            fontFamily: 'DidactGothic-Regular',
            position: "relative",
            paddingVertical: 10,
        },
    }
)
export default TermsPage;
